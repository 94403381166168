import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import VueTinymce from '@packy-tang/vue-tinymce'
// import Plugin from 'v-fit-columns';
// Vue.use(Plugin);

Vue.use(VueTinymce);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
