export default {
    // 存储状态（变量）
    state: {
        currentMenu: null,
        isCollapse: false,
        tabsList: [
            {
                value: "/",
                name: "首页",
            }
        ]
    },
    // 修改状态，并且是同步的。在组件中使用$store.commit('',params)。这个和我们组件中的自定义事件类似。
    mutations: {
        selectMenu(state, val) {
            console.log(state)
            console.log(val)
            if (val.name !== '首页') {
                state.currentMenu = val;
                let result = state.tabsList.findIndex(item => item.name === val.name);
                result === -1 ? state.tabsList.push(val) : ''
            } else {
                state.currentMenu = null
            }
        },
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        closeTab(state, val) {
            let result = state.tabsList.findIndex(item => item.name === val.name);
            console.log(result)
            state.tabsList.splice(result, 1);
        }

    },
    // 异步操作。在组件中使用是$store.dispath('')
    actions: {

    }
}